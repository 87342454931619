import React, {useEffect} from "react";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import {loadTextsByNamespace} from "../../server/helper/ssrHelper";
import config from "../config/main.config";
import {jumpToTop} from "../helper/util";
import EditTextComponent from "../components/editText/EditTextComponent";
import EditHTMLComponent from "../components/editHTML/EditHTMLComponent";

const DataPrivacy = () => {

    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, [])
    const namespace = 'dataPrivacy';

    return (
        <div className={'grid ui'} style={{paddingTop: '300px'}}>
            <div className={'centered row  page-content-wrapper'}>
                <div className={'sixteen wide column'}>
                    <div style={{display: 'none'}}>
                        <BreadCrumbs breadCrumbData={[{title: 'dataPrivacy', url: '/data-privacy'}]}/>
                    </div>
                </div>
                <div className={'sixteen wide column'}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <EditHTMLComponent id={`${namespace}-text-1`} namespace={namespace}/>
                </div>
            </div>
        </div>
    )
}

DataPrivacy.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'dataPrivacy').then((text) => {
        return text;
    })
}

export default DataPrivacy
